import Button from '@/components/buttons';
import { toDisplayDate, toDisplayDateTime } from '@/functions/formatDate';
import { breakpoints } from '@/global/theme';
import { ReactElement, ReactNode, useState } from 'react';
import { styled } from 'styled-components';
import ConfirmationModal, { ConfirmationModalProps } from '../confirmationModal';
import Pagination, { Props as PaginationProps } from '../pagination';

type KeysOfValue<T, TCondition> = {
    //https://www.totaltypescript.com/get-keys-of-an-object-where-values-are-of-a-given-type
    [K in keyof T]: T[K] extends TCondition ? K : never;
}[keyof T];

export type TableColumn<TData> = {
    accessorKey?: KeysOfValue<TData, string | number | File>;
    header: string;
    id?: string;
    accessorFn?: (row: TData) => ReactNode;
    type?: 'text' | 'date' | 'datetime';
};
type DeleteTableItemModalProps = Omit<ConfirmationModalProps, 'handleClose' | 'handleConfirm' | 'isOpen'>;
type Props<TData extends Object> = {
    items: TData[];
    columns: TableColumn<TData>[];
    pagination?: PaginationProps;
    getRowIdentifier: (item: TData) => any;
    handleEdit?: (item: TData) => void;
    handleDelete?: (item: TData) => void;
    getDeleteConfirmationProps?: (item: TData) => DeleteTableItemModalProps;
    customRowActions?: (item: TData) => ReactNode;
    createButtonText?: string;
    handleCreateButtonClick?: () => void;
};
const Table = <TData extends Object>({
    items,
    columns,
    pagination,
    handleEdit,
    handleDelete,
    getRowIdentifier,
    customRowActions,
    createButtonText,
    getDeleteConfirmationProps,
    handleCreateButtonClick,
}: Props<TData>): ReactElement => {
    const hasActions = handleEdit || handleDelete || customRowActions;
    const [deleteModalProps, setDeleteModalProps] = useState<ConfirmationModalProps | null>();
    const handleDeleteButtonClick = (item: TData): void => {
        getDeleteConfirmationProps
            ? setDeleteModalProps({
                  isOpen: true,
                  handleConfirm: () => {
                      handleDelete?.(item);
                      setDeleteModalProps(null);
                  },
                  ...getDeleteConfirmationProps(item),
              })
            : handleDelete?.(item);
    };
    return (
        <StyledWrapper>
            {createButtonText && (
                <div className="createBtn">
                    <Button onClick={handleCreateButtonClick}>{createButtonText}</Button>
                </div>
            )}
            <StyledTable>
                <thead>
                    <tr>
                        {columns.map(({ header }) => (
                            <th key={header}>{header}</th>
                        ))}
                        {hasActions && <th>Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {items.map((item) => {
                        const rowId = getRowIdentifier(item);
                        return (
                            <tr key={rowId}>
                                {columns.map((column) => {
                                    return (
                                        <td data-th={column.header} key={column.header}>
                                            {column.accessorKey
                                                ? parseCellValue(item[column.accessorKey] as string, column)
                                                : column?.accessorFn?.(item)}
                                        </td>
                                    );
                                })}
                                {hasActions && (
                                    <StyledActions className="actions-column">
                                        <div>
                                            {customRowActions && customRowActions(item)}
                                            {handleEdit && (
                                                <button className="editBtn" onClick={() => handleEdit(item)}>
                                                    Edit
                                                </button>
                                            )}
                                            {handleDelete && (
                                                <button
                                                    className="deleteBtn"
                                                    onClick={() => handleDeleteButtonClick(item)}
                                                >
                                                    Delete
                                                </button>
                                            )}
                                        </div>
                                    </StyledActions>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </StyledTable>
            {pagination && <Pagination {...pagination} />}
            {deleteModalProps && (
                <ConfirmationModal
                    handleClose={() => setDeleteModalProps(null)}
                    confirmButtonText="Delete"
                    {...deleteModalProps}
                />
            )}
        </StyledWrapper>
    );
};

const StyledTable = styled.table`
    width: 100%;
    margin-bottom: 1rem;

    tr {
        border: 0.06rem solid ${(props) => props.theme.palette.gray};
    }

    tr:first-child {
        border-top: 1px solid ${(props) => props.theme.palette.primary};
    }

    tr:hover {
        background-color: #eaeef7;
        cursor: pointer;
    }

    th {
        background-color: ${(props) => props.theme.palette.primary};
        color: white;
    }

    th,
    td {
        padding: 0.5rem 0.25rem;
    }

    thead tr th {
        text-align: start;
    }

    thead tr {
        border: 0.12rem solid ${(props) => props.theme.palette.primary};
    }
    thead tr:hover {
        cursor: default;
    }
    a {
        color: ${(props) => props.theme.palette.secondary};
        &:hover {
            color: ${(props) => props.theme.palette.primary};
        }
    }

    @media ${breakpoints.sm} {
        th,
        td {
            display: block;
        }
        thead {
            display: none;
        }
        td:not(.actions-column):before {
            content: attr(data-th) ': ';
            font-weight: 600;
        }
    }
`;

const StyledActions = styled.td`
    gap: 1rem;
    min-height: 100%;
    font-size: 1rem;

    div {
        display: flex;
        gap: 1rem;
    }
    button:hover {
        opacity: 0.5;
    }
    .editBtn {
        color: ${(props) => props.theme.palette.primary};
    }
    .deleteBtn {
        color: ${(props) => props.theme.palette.error};
    }
`;

const StyledWrapper = styled.div`
    @media ${breakpoints.sm} {
        width: 100%;
        margin-left: 0;
    }
    .createBtn {
        display: flex;
        justify-content: end;
        margin-bottom: 1rem;
    }
`;

const parseCellValue = <TData,>(value: string, column: TableColumn<TData>): ReactNode => {
    if (column.type === 'date') {
        return toDisplayDate(value);
    }
    if (column.type === 'datetime') {
        return toDisplayDateTime(value);
    }
    return value;
};

export default Table;
