import Table from '@/components/table';
import { useFetchPaginated } from '@/hooks/useFetch';
import { FunctionComponent } from 'react';
import { getPaginatedVideos } from '@/communication/video';
import { useNavigate } from 'react-router-dom';

const VideosList: FunctionComponent = () => {
    const { data: response, paginationData } = useFetchPaginated({
        queryFn: (page, itemsPerPage) => getPaginatedVideos({ page, itemsPerPage }),
    });
    const nav = useNavigate();

    return (
        <Table
            items={response?.videos ?? []}
            getRowIdentifier={(item) => item.id}
            columns={[
                { accessorKey: 'id', header: 'Id' },
                { accessorKey: 'url', header: 'URL' },
                { accessorKey: 'title', header: 'Title' },
                { accessorKey: 'image', header: 'Image' },
                { accessorKey: 'description', header: 'Description' },
            ]}
            pagination={paginationData}
            handleEdit={(video) => nav(`${video.id}`)}
            createButtonText="Create Video"
            handleCreateButtonClick={() => nav('create')}
        />
    );
};
export default VideosList;
