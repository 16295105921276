import { api } from '@/libs/axios';
import { PaginationRequest, PaginationResponse } from '@/types/communication/pagination';
import Feature from '../types/entities/feature';
import { createFormDataFromObject } from '@/functions/transformObjectToFormData';

export const getPaginatedFeatures = async (
    request?: PaginationRequest,
): Promise<PaginationResponse<Feature, 'features'>> => {
    return api.get('/features', { params: request });
};

export const createFeature = async (feature: Partial<Feature>): Promise<Feature> => {
    return api.post(
        '/features',
        createFormDataFromObject({
            title: feature.title,
            content: feature.content,
            image: feature.image,
        }),
        { headers: { 'Content-Type': 'multipart/form-data' } },
    );
};

export const editFeature = async (feature: Partial<Feature>): Promise<Feature> => {
    return api.post(
        `features/${feature?.id}`,
        createFormDataFromObject({
            title: feature.title,
            content: feature.content,
            image: feature.image,
        }),
        { headers: { 'Content-Type': 'multipart/form-data' } },
    );
};

export const getFeature = async (featureId?: number): Promise<Feature> => {
    return api.get(`features/${featureId}`);
};
