import Table from '@/components/table';
import { useFetchPaginated } from '@/hooks/useFetch';
import { FunctionComponent } from 'react';
import { getPaginatedSubscription } from '../../../communication/subscription';

const SubscriptionsList: FunctionComponent = () => {
    const { data: response, paginationData } = useFetchPaginated({
        queryFn: (page, itemsPerPage) => getPaginatedSubscription({ page, itemsPerPage }),
    });

    return (
        <Table
            items={response?.subscriptions ?? []}
            getRowIdentifier={(item) => item.id}
            columns={[
                { accessorKey: 'id', header: 'Id' },
                { accessorKey: 'email', header: 'Email' },
                { accessorKey: 'created_at', header: 'Created at', type: 'datetime' },
            ]}
            pagination={paginationData}
        />
    );
};
export default SubscriptionsList;
