import { api } from '@/libs/axios';
import { PaginationRequest, PaginationResponse } from '@/types/communication/pagination';
import Tag from '../types/entities/tag';

export const getPaginatedTags = async (request?: PaginationRequest): Promise<PaginationResponse<Tag, 'tags'>> => {
    return api.get('/tags', { params: request });
};

export const getTag = async (tagId?: number): Promise<Tag> => {
    return api.get(`tags/${tagId}`);
};

export const createTag = async (tag: Pick<Tag, 'name'>): Promise<Tag> => {
    return api.post('/tags', tag);
};

export const editTag = async (tag: Pick<Tag, 'id' | 'name'>): Promise<Tag> => {
    return api.put(`tags/${tag?.id}`, { name: tag.name });
};

export const deleteTag = async (tagId: number): Promise<Tag> => {
    return api.delete(`tags/${tagId}`);
};
