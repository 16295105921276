import { theme } from '@/global/theme';
import { FunctionComponent } from 'react';
import { styled } from 'styled-components';
interface Props {
    headline?: string;
    description?: string;
}
const NoItems: FunctionComponent<Props> = ({ headline = 'No items', description = 'No items in the list' }) => {
    return (
        <StyledNoItems>
            <h1>{headline}</h1>
            <p>{description}</p>
        </StyledNoItems>
    );
};

export default NoItems;

const StyledNoItems = styled.div`
    min-width: 100%;
    text-align: center;
    color: ${theme.palette.darkGray};
`;
