import { TypographyPage } from '@/pages/typography';
import { Navigate, RouteObject } from 'react-router-dom';
import MainLayout from '../components/layouts/mainLayout';
import BlogsList from '../pages/blogs/list';
import CharacteristicsList from '../pages/characteristics/list';
import FaqsList from '../pages/faqs/list';
import FeaturesList from '../pages/features/list';
import ReferencesList from '../pages/references/list';
import SubscriptionsList from '../pages/subscriptions/list';
import TagsList from '../pages/tags/list';
import VideosList from '../pages/videos/list';
import TagDetails from '../pages/tags/details';
import FaqDetails from '@/pages/faqs/details';
import VideoDetails from '@/pages/videos/details';
import CharacteristicDetails from '@/pages/characteristics/details';
import ReferenceDetails from '@/pages/references/details';
import FeatureDetails from '@/pages/features/details';
import BlogDetails from '@/pages/blogs/details';
import NotFound from '@/pages/notFound';
import Login from '@/pages/login';

const protectedRoutes: RouteObject[] = [
    {
        path: '*',
        element: <MainLayout />,
        children: [
            { path: '', element: <Navigate to="/blogs" /> },
            {
                path: 'blogs/*',
                children: [
                    {
                        path: '',
                        element: <BlogsList />,
                    },
                    {
                        path: 'create',
                        element: <BlogDetails createMode />,
                    },
                    {
                        path: ':blogId',
                        element: <BlogDetails />,
                    },
                ],
            },
            {
                path: 'tags/*',
                children: [
                    {
                        path: '',
                        element: <TagsList />,
                    },
                    {
                        path: 'create',
                        element: <TagDetails createMode />,
                    },
                    {
                        path: ':tagId',
                        element: <TagDetails />,
                    },
                ],
            },
            {
                path: 'characteristics/*',
                children: [
                    {
                        path: '',
                        element: <CharacteristicsList />,
                    },
                    {
                        path: 'create',
                        element: <CharacteristicDetails createMode />,
                    },
                    {
                        path: ':characteristicId',
                        element: <CharacteristicDetails />,
                    },
                ],
            },
            {
                path: 'videos/*',
                children: [
                    {
                        path: '',
                        element: <VideosList />,
                    },
                    {
                        path: 'create',
                        element: <VideoDetails createMode />,
                    },
                    {
                        path: ':videoId',
                        element: <VideoDetails />,
                    },
                ],
            },
            {
                path: 'references/*',
                children: [
                    {
                        path: '',
                        element: <ReferencesList />,
                    },
                    {
                        path: ':referenceId',
                        element: <ReferenceDetails />,
                    },
                ],
            },
            {
                path: 'features/*',
                children: [
                    {
                        path: '',
                        element: <FeaturesList />,
                    },
                    {
                        path: 'create',
                        element: <FeatureDetails createMode />,
                    },
                    {
                        path: ':featureId',
                        element: <FeatureDetails />,
                    },
                ],
            },
            { path: 'subscriptions', element: <SubscriptionsList /> },
            {
                path: 'faqs/*',
                children: [
                    {
                        path: '',
                        element: <FaqsList />,
                    },
                    {
                        path: 'create',
                        element: <FaqDetails createMode />,
                    },
                    {
                        path: ':faqId',
                        element: <FaqDetails />,
                    },
                ],
            },
            { path: '*', element: <NotFound /> },
        ],
    },
];

const publicRoutes: RouteObject[] = [
    { path: '/', element: <Login /> },
    { path: '*', element: <NotFound /> },
];

const commonRoutes: RouteObject[] = [{ path: 'typography', element: <TypographyPage /> }];

export const getRoutes = (isUserLoggedIn: boolean): RouteObject[] =>
    isUserLoggedIn ? [...protectedRoutes, ...commonRoutes] : [...publicRoutes, ...commonRoutes];
