import Table from '@/components/table';
import { useFetchPaginated } from '@/hooks/useFetch';
import { FunctionComponent } from 'react';
import { getPaginatedFaqs } from '@/communication/faq';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

const FaqsList: FunctionComponent = () => {
    const { data: response, paginationData } = useFetchPaginated({
        queryFn: (page, itemsPerPage) => getPaginatedFaqs({ page, itemsPerPage }),
    });
    const nav = useNavigate();

    return (
        <Table
            items={response?.faqs ?? []}
            getRowIdentifier={(item) => item.id}
            columns={[
                { accessorKey: 'id', header: 'Id' },
                { accessorKey: 'title', header: 'Title' },
                {
                    id: 'answer',
                    header: 'Answer',
                    accessorFn: (faq) => {
                        const sanitizedFaq = DOMPurify.sanitize(faq.answer);
                        return <span dangerouslySetInnerHTML={{ __html: sanitizedFaq }} />;
                    },
                },
            ]}
            pagination={paginationData}
            handleEdit={(faq) => nav(`${faq.id}`)}
            createButtonText="Create Faq"
            handleCreateButtonClick={() => nav('create')}
        />
    );
};

export default FaqsList;
