import { api } from '@/libs/axios';
import { PaginationRequest, PaginationResponse } from '@/types/communication/pagination';
import Reference from '../types/entities/refrerence';
import { createFormDataFromObject } from '@/functions/transformObjectToFormData';

export const getPaginatedReferences = async (
    request?: PaginationRequest,
): Promise<PaginationResponse<Reference, 'references'>> => {
    return api.get('/references', { params: request });
};

export const editReference = async (reference: Partial<Reference>): Promise<Reference> => {
    return api.post(
        `references/${reference?.id}`,
        createFormDataFromObject({
            content: reference.content,
            name: reference.name,
            location: reference.location,
            image: reference.image,
        }),
        { headers: { 'Content-Type': 'multipart/form-data' } },
    );
};

export const getReference = async (referencesId?: number): Promise<Reference> => {
    return api.get(`references/${referencesId}`);
};
