import { getPaginatedBlogs } from '@/communication/blog';
import Table from '@/components/table';
import { useFetchPaginated } from '@/hooks/useFetch';
import { FunctionComponent } from 'react';
import { FRONTEND_URL } from '@/constants';
import { useNavigate } from 'react-router-dom';

const BlogsList: FunctionComponent = () => {
    const { data: response, paginationData } = useFetchPaginated({
        queryFn: (page, itemsPerPage) => getPaginatedBlogs({ page, itemsPerPage }),
    });
    const nav = useNavigate();

    return (
        <Table
            items={response?.blogs ?? []}
            getRowIdentifier={(item) => item.id}
            columns={[
                { accessorKey: 'id', header: 'Id' },
                { accessorKey: 'title', header: 'Title' },
                { accessorKey: 'url', header: 'URL' },
                { accessorKey: 'featured', header: 'Featured' },
                { accessorKey: 'published', header: 'Published' },
                {
                    accessorFn: (blog) => (
                        <a href={FRONTEND_URL + 'blog/' + blog.url} target="_blank" rel="noreferrer">
                            Visit
                        </a>
                    ),
                    header: 'Link',
                },
            ]}
            pagination={paginationData}
            handleEdit={(blog) => nav(`${blog.id}`)}
            createButtonText="Create Blog"
            handleCreateButtonClick={() => nav('create')}
        />
    );
};

export default BlogsList;
