import { FunctionComponent, useRef } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { breakpoints } from '@/global/theme';
import useOnClickOutside from '@/hooks/useOnClickOutside';

const SIDEBAR_ITEMS = [
    { location: '/blogs', name: 'Blogs' },
    { location: '/faqs', name: 'Faqs' },
    { location: '/tags', name: 'Tags' },
    { location: '/characteristics', name: 'Characteristics' },
    { location: '/videos', name: 'Videos' },
    { location: '/references', name: 'References' },
    { location: '/features', name: 'Features' },
    { location: '/subscriptions', name: 'Subscriptions' },
];

interface Props {
    isMobile?: boolean;
    handleClose?: () => void;
}

const SideBar: FunctionComponent<Props> = ({ isMobile, handleClose }) => {
    const sidebarRef = useRef(null);
    useOnClickOutside(sidebarRef, handleClose);
    return (
        <StyledSideBar ref={sidebarRef} className={isMobile ? 'mobile' : 'desktop'}>
            <div className="menu">
                {SIDEBAR_ITEMS.map(({ location, name }) => (
                    <NavLink key={location} to={location} onClick={handleClose}>
                        {name}
                    </NavLink>
                ))}
            </div>
        </StyledSideBar>
    );
};

const StyledSideBar = styled.div`
    &.desktop {
        position: fixed;
        top: 50px;
        width: 200px;
        height: 100%;
        background-color: ${(props) => props.theme.palette.secondary};
        margin-right: 200px;

        @media ${breakpoints.sm} {
            display: none;
        }
        .menu {
            display: flex;
            flex-direction: column;
            padding-top: 2rem;
            font-size: 1rem;
            font-weight: 600;
            a {
                color: ${(props) => props.theme.palette.primary};
                padding-left: 1rem;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                border-left: 3px solid ${(props) => props.theme.palette.secondary};
                &:hover {
                    background-color: ${(props) => props.theme.palette.primary};
                    color: white;
                    border-left: 3px solid white;
                    transition: all 0.15s ease-in-out;
                }
                &.active {
                    color: ${(props) => props.theme.palette.primary};
                    background-color: white;
                    border-left: 3px solid ${(props) => props.theme.palette.primary};
                }
            }
        }
    }

    &.mobile {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 300px;
        width: 70%;
        max-width: 450px;
        background-color: ${(props) => props.theme.palette.primary};
        transform: translateX(100%);
        transition: all 0.3s ease-in-out;
        z-index: 100;
        @media ${breakpoints.xs} {
            right: 260px;
        }
        .menu {
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            a {
                display: block;
                font-size: 22px;
                font-weight: 600;
                color: white;
                padding: 0.5rem 1.5rem;
                &:hover {
                    color: ${(props) => props.theme.palette.secondary};
                    transition: all 0.15s ease-in-out;
                }
            }
        }
    }
`;

export default SideBar;
