import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import TopBar from '@/components/topBar';
import SideBar from '@/components/sideBar';
import styled from 'styled-components';
import { breakpoints } from '@/global/theme';

const MainLayout: FunctionComponent = () => {
    return (
        <>
            <TopBar />
            <SideBar />
            <StyledWrapper>
                <Outlet />
            </StyledWrapper>
        </>
    );
};

const StyledWrapper = styled.main`
    width: calc(100% - 200px);
    margin-left: 200px;
    margin-top: 5rem;
    padding: 0 1rem;
    @media ${breakpoints.sm} {
        width: 100%;
        margin-left: 0;
    }
`;
export default MainLayout;
