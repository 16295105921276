import { Palette, Theme, Shadows, BreakpointSizes, Breakpoints } from '@/types/theme';

const palette: Palette = {
    primary: '#091D56',
    secondary: '#4A8CEA',
    error: '#D32F2F',
    warning: '#ED6C02',
    gray: '#E6E6E6',
    darkGray: '#6B7280',
    black: '#000000',
};

const shadows: Shadows = {
    main: '0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2), 0 0.375rem 1.25rem 0 rgba(0, 0, 0, 0.19)',
};

const sizes: BreakpointSizes = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1400,
};

export const breakpoints: Breakpoints = {
    xs: `(max-width: ${sizes.xs}px)`,
    sm: `(max-width: ${sizes.sm}px)`,
    md: `(max-width: ${sizes.md}px)`,
    lg: `(max-width: ${sizes.lg}px)`,
    xl: `(max-width: ${sizes.xl}px)`,
};

export const theme: Theme = { palette: palette, shadows, breakpointSizes: sizes, breakpoints };
