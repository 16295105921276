import Axios from 'axios';
import { authTokenInterceptor } from './interceptors/authTokenInterceptor';
import { errorResponseInterceptor } from './interceptors/errorResponseInterceptor';
import { extractResponseDataInterceptor } from './interceptors/extractResponseDataInterceptor';
import { API_URL } from '@/constants';

export const api = Axios.create({
    baseURL: API_URL,
});

api.interceptors.request.use(authTokenInterceptor);

api.interceptors.response.use(extractResponseDataInterceptor, errorResponseInterceptor);
