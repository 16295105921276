import { deleteTag, getPaginatedTags } from '@/communication/tag';
import Table from '@/components/table';
import { useFetchPaginated } from '@/hooks/useFetch';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

const TagsList: FunctionComponent = () => {
    const {
        data: response,
        paginationData,
        refetch,
    } = useFetchPaginated({
        queryFn: (page, itemsPerPage) => getPaginatedTags({ page, itemsPerPage }),
    });
    const nav = useNavigate();
    return (
        <Table
            items={response?.tags ?? []}
            getRowIdentifier={(item) => item.id}
            columns={[
                { accessorKey: 'id', header: 'Id' },
                { accessorKey: 'name', header: 'Name' },
                { accessorKey: 'created_at', header: 'Created at', type: 'datetime' },
                { accessorKey: 'updated_at', header: 'Updated at', type: 'datetime' },
            ]}
            pagination={paginationData}
            handleEdit={(tag) => nav(`${tag.id}`)}
            handleDelete={async (tag) => {
                await deleteTag(tag.id);
                refetch();
            }}
            getDeleteConfirmationProps={(tag) => ({
                confirmationMessage: `Are you sure that you want to delete ${tag.name}?`,
            })}
            createButtonText="Create Tag"
            handleCreateButtonClick={() => nav('create')}
        />
    );
};

export default TagsList;
