import Table from '@/components/table';
import { useFetchPaginated } from '@/hooks/useFetch';
import { FunctionComponent } from 'react';
import { getPaginatedCharacteristics } from '@/communication/characteristic';
import { useNavigate } from 'react-router-dom';

const CharacteristicsList: FunctionComponent = () => {
    const { data: response, paginationData } = useFetchPaginated({
        queryFn: (page, itemsPerPage) => getPaginatedCharacteristics({ page, itemsPerPage }),
    });
    const nav = useNavigate();

    return (
        <Table
            items={response?.characteristic ?? []}
            getRowIdentifier={(item) => item.id}
            columns={[
                { accessorKey: 'id', header: 'Id' },
                { accessorKey: 'title', header: 'Title' },
                { accessorKey: 'content', header: 'Content' },
                { accessorKey: 'image', header: 'Image' },
            ]}
            pagination={paginationData}
            handleEdit={(characteristic) => nav(`${characteristic.id}`)}
            createButtonText="Create Characteristic"
            handleCreateButtonClick={() => nav('create')}
        />
    );
};

export default CharacteristicsList;
