import { FunctionComponent } from 'react';
import Button, { ButtonProps } from '@/components/buttons';
import { useNavigate } from 'react-router-dom';

export const BackButton: FunctionComponent<ButtonProps> = (props) => {
    const nav = useNavigate();
    return (
        <Button variant="outlined" onClick={() => nav(-1)} {...props}>
            {props.children ? props.children : 'Back'}
        </Button>
    );
};
