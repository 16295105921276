import InputField from '@/components/inputs/inputField';
import React, { FunctionComponent, useState } from 'react';
import Button from '@/components/buttons';
import styled from 'styled-components';
import { ServerError } from '@/types/communication/serverResponse';
import { loginUser } from '@/communication/auth';
import { LoginDto } from '@/types/communication/auth';
import { useStore } from '@/store';

const Login: FunctionComponent = () => {
    const saveToken = useStore((store) => store.saveToken);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [validationErrors, setValidationErrors] = useState<any>();
    const [formValues, setFormValues] = useState<LoginDto>({
        username: '',
        password: '',
    });

    const handleSubmit = async (): Promise<void> => {
        setIsSubmitting(true);
        setValidationErrors(null);
        try {
            const loginResponse = await loginUser(formValues);
            saveToken(loginResponse.token);
        } catch (error) {
            const serverError = error as ServerError;
            if (serverError?.type === 'validation') {
                setValidationErrors(serverError.payload);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleChange = (fieldTitle: keyof LoginDto, value): void => {
        setFormValues((prevValues) => ({ ...prevValues, [fieldTitle]: value }));
    };

    return (
        <StyledContent>
            <InputField
                value={formValues.username}
                label="Email"
                errorMessage={validationErrors?.username}
                onChange={(e) => handleChange('username', e.target.value)}
            />
            <InputField
                label="Password"
                type="password"
                errorMessage={validationErrors?.password}
                onChange={(e) => handleChange('password', e.target.value)}
            />
            <div className="form-actions">
                <Button onClick={handleSubmit} disabled={isSubmitting}>
                    Uloguj se
                </Button>
            </div>
        </StyledContent>
    );
};

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: min(500px, 90%);
    margin: auto;
    padding: 1rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-top: 8px solid ${(props) => props.theme.palette.primary};
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    .form-actions {
        display: flex;
        gap: 1rem;
    }
`;

export default Login;
