import { CKEditorProps, useCKEditor } from 'ckeditor4-react';
import { FunctionComponent, useEffect, useState } from 'react';
import InputFieldWrapper, { BaseInputProps } from '../inputs/inputFieldWrapper';
type EditorProps = CKEditorProps<any>['props'];
type Props = BaseInputProps & Omit<EditorProps, 'editor' | 'data'>;

export const TextEditor: FunctionComponent<Props> = (props) => {
    const { label, errorMessage, helperText, value, onChange, ...ckEditorProps } = props;
    const [element, setElement] = useState<HTMLElement | null>(null);
    const { editor, status } = useCKEditor({
        element: element,
        initContent: value,
        dispatchEvent: ({ payload }) => {
            const newData = payload?.editor?.getData();
            if (newData !== undefined) {
                onChange?.(newData);
            }
        },
        subscribeTo: ['change'],
        config: {
            allowedContent: true,
        },
        ...ckEditorProps,
    });
    useEffect(() => {
        if (editor && editor.getData() !== value && status === 'ready') {
            editor.setData(value);
        }
    }, [value, editor, status]);
    return (
        <InputFieldWrapper label={label} helperText={helperText} errorMessage={errorMessage}>
            <div ref={setElement}></div>
        </InputFieldWrapper>
    );
};
