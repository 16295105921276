import { api } from '@/libs/axios';
import { PaginationRequest, PaginationResponse } from '@/types/communication/pagination';
import Faq from '../types/entities/faq';

export const getPaginatedFaqs = async (request?: PaginationRequest): Promise<PaginationResponse<Faq, 'faqs'>> => {
    return api.get('/faqs', { params: request });
};

export const createFaq = async (faq: Partial<Faq>): Promise<Faq> => {
    return api.post('/faqs', faq);
};

export const editFaq = async (faq: Partial<Faq>): Promise<Faq> => {
    return api.put(`faqs/${faq?.id}`, {
        title: faq.title,
        answer: faq.answer,
        published: faq.published,
        sort: faq.sort,
    });
};

export const getFaq = async (faqId?: number): Promise<Faq> => {
    return api.get(`faqs/${faqId}`);
};
