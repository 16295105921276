import { api } from '@/libs/axios';
import { PaginationRequest, PaginationResponse } from '@/types/communication/pagination';
import Characteristic from '../types/entities/characteristic';
import { createFormDataFromObject } from '@/functions/transformObjectToFormData';

export const getPaginatedCharacteristics = async (
    request?: PaginationRequest,
): Promise<PaginationResponse<Characteristic, 'characteristic'>> => {
    return api.get('/characteristics', { params: request });
};

export const createCharacteristic = async (characteristic: Partial<Characteristic>): Promise<Characteristic> => {
    return api.post(
        '/characteristics',
        createFormDataFromObject({
            title: characteristic.title,
            content: characteristic.content,
            image: characteristic.image,
        }),
        { headers: { 'Content-Type': 'multipart/form-data' } },
    );
};

export const editCharacteristic = async (characteristic: Partial<Characteristic>): Promise<Characteristic> => {
    return api.post(
        `characteristics/${characteristic?.id}`,
        createFormDataFromObject({
            title: characteristic.title,
            content: characteristic.content,
            image: characteristic.image,
        }),
        { headers: { 'Content-Type': 'multipart/form-data' } },
    );
};

export const getCharacteristic = async (characteristicId?: number): Promise<Characteristic> => {
    return api.get(`characteristics/${characteristicId}`);
};
