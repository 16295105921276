import { FunctionComponent } from 'react';
import Modal, { ModalProps } from '../modal';
import Button from '../buttons';
import styled from 'styled-components';
export type ConfirmationModalProps = Omit<ModalProps, 'children'> & {
    confirmationMessage: string;
    handleConfirm: () => void;
    confirmButtonText?: string;
};
const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = (props) => {
    const { confirmButtonText, confirmationMessage, handleConfirm, ...modalProps } = props;
    return (
        <Modal {...modalProps}>
            <StyledContent>
                {confirmationMessage}
                <div className="modal-actions">
                    <Button onClick={props.handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm}>{confirmButtonText}</Button>
                </div>
            </StyledContent>
        </Modal>
    );
};
const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
    .modal-actions {
        display: flex;
        gap: 1rem;

        justify-content: end;
    }
`;

export default ConfirmationModal;
