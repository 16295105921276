import AppError from '@/types/appError';
import { useEffect, useReducer, useRef, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from '../constants';
import { ServerError } from '../types/communication/serverResponse';

interface State<T> {
    data?: T;
    error?: ServerError;
    status: 'loading' | 'refetching' | 'fetched' | 'error' | 'idle';
}
interface FetchConfig<T> {
    queryFn: () => Promise<T | AppError>;
    disabled?: boolean;
}
interface FetchPaginatedConfig<T> {
    queryFn: (page?: number, itemsPerPage?: number) => Promise<T | AppError>;
    itemsPerPage?: number;
}

interface Return<T> extends State<T> {
    refetch: () => Promise<void>;
}
interface ReturnPaginated<T> extends Return<T> {
    paginationData: { currentPage: number; onPageChange: (newPage: number) => void; pageCount: number };
}

type Action<T> =
    | { type: 'loading' }
    | { type: 'refetching' }
    | { type: 'idle' }
    | { type: 'fetched'; payload: T }
    | { type: 'error'; payload: ServerError };

export function useFetch<T = unknown>({ queryFn, disabled }: FetchConfig<T>, dependencyArray?: any[]): Return<T> {
    const cancelRequest = useRef<boolean>(false);

    const initialState: State<T> = {
        error: undefined,
        data: undefined,
        status: 'loading',
    };

    const fetchReducer = (prevState: State<T>, action: Action<T>): State<T> => {
        switch (action.type) {
            case 'loading':
                return { ...initialState, status: 'loading' };
            case 'idle':
                return { ...initialState, status: 'idle' };
            case 'refetching':
                return { ...initialState, status: 'refetching', data: prevState.data };
            case 'fetched':
                return { ...initialState, status: 'fetched', data: action.payload };
            case 'error':
                return { ...initialState, status: 'error', error: action.payload };
            default:
                return prevState;
        }
    };

    const [state, dispatch] = useReducer(fetchReducer, initialState);
    const fetchData = async (): Promise<void> => {
        dispatch({ type: state.data ? 'refetching' : 'loading' });
        try {
            const response = await queryFn();
            const data = response as T;
            if (cancelRequest.current) return;
            dispatch({ type: 'fetched', payload: data });
        } catch (error) {
            if (cancelRequest.current) return;
            dispatch({ type: 'error', payload: error as ServerError });
        }
    };

    useEffect(
        () => {
            if (disabled) {
                dispatch({ type: 'idle' });
                return;
            }
            cancelRequest.current = false;
            fetchData();

            return () => {
                cancelRequest.current = true;
            };
        },
        dependencyArray ?? [disabled],
    );

    return { ...state, refetch: fetchData };
}

export function useFetchPaginated<T extends { count: number }>(
    config: FetchPaginatedConfig<T>,
    dependencyArray?: any[],
): ReturnPaginated<T> {
    const [currentPage, setCurrentPage] = useState(1);
    const fetchedData = useFetch({ ...config, queryFn: () => config.queryFn(currentPage, config.itemsPerPage) }, [
        dependencyArray,
        currentPage,
    ]);
    const numberOfPages = Math.ceil((fetchedData.data?.count ?? 0) / (config?.itemsPerPage ?? DEFAULT_PAGE_SIZE));
    return {
        paginationData: {
            currentPage,
            onPageChange: setCurrentPage,
            pageCount: numberOfPages ?? 0,
        },
        ...fetchedData,
    };
}
