import { AxiosError } from 'axios';
import { ServerError } from '@/types/communication/serverResponse';
import { useStore } from '@/store';

type ServerValidationErrorResponse = {
    errors: { code: boolean; field: string; message: any }[];
};

type ServerErrorResponse = ServerValidationErrorResponse;
export const errorResponseInterceptor = async (serverError: AxiosError<ServerErrorResponse>): Promise<string> => {
    if (serverError.response?.status === 404) {
        window.location.replace('/NotFound');
    }
    if (serverError.response?.status === 401) {
        const logout = useStore.getState().logout;
        logout();
        window.location.replace('/');
    }
    const maybeValidationErrors = serverError?.response?.data?.errors;
    if (maybeValidationErrors) {
        const parsedValidationErrors = maybeValidationErrors.reduce(
            (object, validationError) => Object.assign(object, { [validationError.field]: validationError.message }),
            {},
        );
        const error: ServerError = { type: 'validation', payload: parsedValidationErrors };
        return Promise.reject(error);
    }
    const error: ServerError = { type: 'unknown', message: 'Error occurred on server' };
    return Promise.reject(error);
};
