import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '@/components/buttons';
import InputField from '@/components/inputs/inputField';
import { createFeature, editFeature, getFeature } from '@/communication/feature';
import { ServerError } from '@/types/communication/serverResponse';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from '@/hooks/useFetch';
import { BackButton } from '@/components/buttons/backButton';

interface Props {
    createMode?: boolean;
}

interface FormValues {
    title: string;
    content: string;
    image: string | File;
}

const FeatureDetails: FunctionComponent<Props> = (props) => {
    const { createMode } = props;
    const { featureId: featureIdParam } = useParams();
    const featureId = Number(featureIdParam);
    const nav = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formValues, setFormValues] = useState<FormValues>({
        title: '',
        content: '',
        image: '',
    });
    const [validationErrors, setValidationErrors] = useState<any>();
    const { data, status } = useFetch({
        queryFn: () => getFeature(featureId),
        disabled: Number.isNaN(featureId),
    });

    const handleChange = (fieldTitle: keyof FormValues, value): void => {
        setFormValues((prevValues) => ({ ...prevValues, [fieldTitle]: value }));
    };

    const handleSubmit = async (): Promise<void> => {
        setIsSubmitting(true);
        setValidationErrors(null);
        try {
            await (createMode ? createFeature(formValues) : editFeature({ id: featureId, ...formValues }));
            nav('/features');
        } catch (error) {
            const serverError = error as ServerError;
            if (serverError?.type === 'validation') {
                setValidationErrors(serverError.payload);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (status === 'fetched' && data) {
            setFormValues(data);
        }
    }, [data, status]);

    if (status === 'loading') {
        return <>Loading</>;
    }
    return (
        <StyledContent>
            <h1>{createMode ? 'Create' : 'Edit'} Feature</h1>
            <InputField
                value={formValues.title}
                label="Title"
                errorMessage={validationErrors?.title}
                onChange={(e) => handleChange('title', e.target.value)}
            />
            <InputField
                value={formValues.content}
                label="Content"
                errorMessage={validationErrors?.content}
                onChange={(e) => handleChange('content', e.target.value)}
            />
            <InputField
                label="Logo"
                type="file"
                errorMessage={validationErrors?.image}
                onChange={(e: React.FormEvent<HTMLInputElement>) => handleChange('image', e.currentTarget.files?.[0])}
            />
            <div className="form-actions">
                <Button onClick={handleSubmit} disabled={isSubmitting}>
                    {createMode ? 'Create' : 'Save'}
                </Button>
                <BackButton />
            </div>
        </StyledContent>
    );
};

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 1140px;
    margin: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    .form-actions {
        display: flex;
        gap: 1rem;
        margin-bottom: 5rem;
    }
`;

export default FeatureDetails;
