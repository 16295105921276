export const createFormDataFromObject = (object: object): FormData =>
    Object.entries(object).reduce(
        (formData, [propertyName, propertyValue]) => (
            Array.isArray(propertyValue)
                ? (propertyValue as any[]).forEach((value) => formData.append(`${propertyName}[]`, value))
                : formData.append(propertyName, propertyValue),
            formData
        ),
        new FormData(),
    );
