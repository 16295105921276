import Table from '@/components/table';
import { useFetchPaginated } from '@/hooks/useFetch';
import { FunctionComponent } from 'react';
import { getPaginatedReferences } from '@/communication/reference';
import { useNavigate } from 'react-router-dom';

const ReferencesList: FunctionComponent = () => {
    const { data: response, paginationData } = useFetchPaginated({
        queryFn: (page, itemsPerPage) => getPaginatedReferences({ page, itemsPerPage }),
    });
    const nav = useNavigate();

    return (
        <Table
            items={response?.references ?? []}
            getRowIdentifier={(item) => item.id}
            columns={[
                { accessorKey: 'id', header: 'Id' },
                { accessorKey: 'content', header: 'Content' },
                { accessorKey: 'name', header: 'Name' },
                { accessorKey: 'location', header: 'Location' },
                { accessorKey: 'image', header: 'Image' },
            ]}
            pagination={paginationData}
            handleEdit={(reference) => nav(`${reference.id}`)}
        />
    );
};
export default ReferencesList;
