import clsx from 'clsx';
import { FunctionComponent, ReactNode, useState } from 'react';
import styled from 'styled-components';

type Tab = {
    name: string;
    content: ReactNode;
};
type Props = {
    tabs: Tab[];
    defaultSelectedTabName?: string;
};

const Tabs: FunctionComponent<Props> = (props) => {
    const { tabs, defaultSelectedTabName } = props;
    const [selectedTabName, setSelectedTabName] = useState<string>(defaultSelectedTabName ?? tabs?.[0].name);
    const Content = tabs.find((t) => t.name === selectedTabName)?.content;
    return (
        <StyledTabs>
            <div className="tabs-list">
                {tabs.map((tab) => (
                    <button
                        key={tab.name}
                        onClick={() => setSelectedTabName(tab.name)}
                        className={clsx('tab-button', { selected: selectedTabName === tab.name })}
                    >
                        {tab.name}
                    </button>
                ))}
            </div>
            <div className="tab-content">{Content}</div>
        </StyledTabs>
    );
};

const StyledTabs = styled.div`
    min-width: 100%;
    .tabs-list {
        max-width: 1140px;
        margin: 1rem auto;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .tab-button {
        &:first-child {
            border-radius: 0.25rem 0 0 0.25rem;
        }
        &:last-child {
            border-radius: 0 0.25rem 0.25rem 0;
        }
        &.selected {
            background-color: ${(props) => props.theme.palette.primary};
            border: 1px solid ${(props) => props.theme.palette.primary};
            color: white;
        }
        &:not(.selected) {
            border: 1px solid gray;
        }
        &:hover {
            opacity: 0.7;
        }
        transition: all 0.4s;
        padding: 0.4rem 1rem;
    }
`;

export default Tabs;
