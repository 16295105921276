import { api } from '@/libs/axios';
import { PaginationRequest, PaginationResponse } from '@/types/communication/pagination';
import Video from '../types/entities/video';
import { createFormDataFromObject } from '@/functions/transformObjectToFormData';

export const getPaginatedVideos = async (request?: PaginationRequest): Promise<PaginationResponse<Video, 'videos'>> => {
    return api.get('/videos', { params: request });
};

export const createVideo = async (video: Partial<Video>): Promise<Video> => {
    return api.post(
        '/videos',
        createFormDataFromObject({
            url: video.url,
            title: video.title,
            sort: video.sort,
            description: video.description,
            image: video.image,
        }),
        { headers: { 'Content-Type': 'multipart/form-data' } },
    );
};

export const editVideo = async (video: Partial<Video>): Promise<Video> => {
    return api.post(
        `videos/${video?.id}`,
        createFormDataFromObject({
            url: video.url,
            title: video.title,
            sort: video.sort,
            description: video.description,
            image: video.image,
        }),
        { headers: { 'Content-Type': 'multipart/form-data' } },
    );
};

export const getVideo = async (videosId?: number): Promise<Video> => {
    return api.get(`videos/${videosId}`);
};
