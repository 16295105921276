import { api } from '@/libs/axios';
import { PaginationRequest, PaginationResponse } from '@/types/communication/pagination';
import BlogPost from '../types/entities/blogPost';
import { createFormDataFromObject } from '@/functions/transformObjectToFormData';
import { transformSentenceToUrl } from '@/functions/transformSentenceToUrl';

export const getPaginatedBlogs = async (
    request?: PaginationRequest,
): Promise<PaginationResponse<BlogPost, 'blogs'>> => {
    return api.get('/blogs', { params: request });
};

export const createBlog = async (blog: Partial<BlogPost>): Promise<BlogPost> => {
    return api.post(
        '/blogs',
        createFormDataFromObject({
            title: blog.title,
            url: blog.url ? blog.url : transformSentenceToUrl(blog.title ?? ''),
            published: blog.published !== undefined && +blog.published,
            featured: blog.featured !== undefined && +blog.featured,
            content: blog.content,
            image: blog.image,
            relatedBlogs: blog.related_blogs?.map((b) => b.id),
            tags: blog.tags?.map((t) => t.id),
            metaTitle: blog.meta_title,
            metaDescription: blog.meta_description,
            metaKeywords: blog.meta_keywords,
            noIndex: blog.no_index,
            noFollow: blog.no_follow,
            hideInSitemap: blog.hide_in_sitemap,
        }),
        { headers: { 'Content-Type': 'multipart/form-data' } },
    );
};

export const editBlog = async (blog: Partial<BlogPost>): Promise<BlogPost> => {
    return api.post(
        `blogs/${blog?.id}`,
        createFormDataFromObject({
            title: blog.title,
            url: blog.url,
            published: blog.published !== undefined && +blog.published,
            featured: blog.featured !== undefined && +blog.featured,
            content: blog.content,
            image: blog.image,
            relatedBlogs: blog.related_blogs?.map((b) => b.id),
            tags: blog.tags?.map((t) => t.id),
            metaTitle: blog.meta_title,
            metaDescription: blog.meta_description,
            metaKeywords: blog.meta_keywords,
            noIndex: blog.no_index,
            noFollow: blog.no_follow,
            hideInSitemap: blog.hide_in_sitemap,
        }),
        { headers: { 'Content-Type': 'multipart/form-data' } },
    );
};

export const getBlog = async (blogId?: number): Promise<BlogPost> => {
    return api.get(`blogs/${blogId}`);
};
