import { createBlog, editBlog, getBlog, getPaginatedBlogs } from '@/communication/blog';
import Button from '@/components/buttons';
import InputField from '@/components/inputs/inputField';
import { useFetch, useFetchPaginated } from '@/hooks/useFetch';
import { ServerError } from '@/types/communication/serverResponse';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getPaginatedTags } from '@/communication/tag';
import SwitchField from '../../../components/inputs/switch';
import SelectField from '../../../components/select';
import { TextEditor } from '@/components/textEditor';
import BlogPost from '../../../types/entities/blogPost';
import Tag from '../../../types/entities/tag';
import Tabs from '../../tabs';
import { BackButton } from '@/components/buttons/backButton';
import { FRONTEND_URL } from '@/constants';

interface Props {
    createMode?: boolean;
}

const MAX_RELATED_BLOGS = 3;
const BlogDetails: FunctionComponent<Props> = (props) => {
    const { createMode } = props;
    const { blogId: blogIdParam } = useParams();
    const { data: blogsResponse } = useFetchPaginated({
        queryFn: (page, itemsPerPage) => getPaginatedBlogs({ page, itemsPerPage }),
        itemsPerPage: 100,
    });
    const { data: tagsResponse } = useFetchPaginated({
        queryFn: (page, itemsPerPage) => getPaginatedTags({ page, itemsPerPage }),
        itemsPerPage: 100,
    });
    const allBlogs = blogsResponse?.blogs ?? [];
    const allTags = tagsResponse?.tags ?? [];
    const blogId = Number(blogIdParam);
    const nav = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formValues, setFormValues] = useState<Partial<BlogPost>>({
        title: '',
        url: '',
        published: 0,
        featured: 0,
        content: '',
        image: '',
        related_blogs: [],
        tags: [],
    });
    const [validationErrors, setValidationErrors] = useState<any>();
    const { data, status } = useFetch({
        queryFn: () => getBlog(blogId),
        disabled: Number.isNaN(blogId),
    });

    const handleChange = (fieldTitle: keyof BlogPost, value): void => {
        setFormValues((prevValues) => ({ ...prevValues, [fieldTitle]: value }));
    };

    const handleSubmit = async (): Promise<void> => {
        setIsSubmitting(true);
        setValidationErrors(null);
        try {
            await (createMode ? createBlog(formValues) : editBlog({ ...formValues, id: blogId }));
            nav('/blogs');
        } catch (error) {
            const serverError = error as ServerError;
            if (serverError?.type === 'validation') {
                setValidationErrors(serverError.payload);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (status === 'fetched' && data) {
            setFormValues(data);
        }
    }, [data, status]);

    if (status === 'loading') {
        return <>Loading</>;
    }
    const Actions = (
        <div className="form-actions">
            <Button onClick={handleSubmit} disabled={isSubmitting}>
                {createMode ? 'Create' : 'Save'}
            </Button>
            <BackButton />
        </div>
    );
    const GeneralTab = (
        <StyledContent>
            <h1>{createMode ? 'Create' : 'Edit'} Blog</h1>
            {formValues.image && !createMode && typeof formValues.image === 'string' && (
                <section>
                    <img src={`${FRONTEND_URL}uploads/${formValues.image}`} alt={'image'} />
                </section>
            )}
            <InputField
                value={formValues.title}
                label="Title"
                errorMessage={validationErrors?.title}
                onChange={(e) => handleChange('title', e.target.value)}
            />
            <InputField
                value={formValues.url}
                label="Url"
                errorMessage={validationErrors?.url}
                onChange={(e) => handleChange('url', e.target.value)}
                disabled={!createMode}
            />
            <SwitchField
                value={formValues.published}
                label="Published"
                errorMessage={validationErrors?.published}
                onChange={(e) => handleChange('published', e.target.checked)}
            />
            <SwitchField
                value={formValues.featured}
                label="Featured"
                errorMessage={validationErrors?.featured}
                onChange={(e) => handleChange('featured', e.target.checked)}
            />
            <TextEditor
                value={formValues.content}
                label="Content"
                errorMessage={validationErrors?.content}
                onChange={(value) => handleChange('content', value)}
            />
            <InputField
                label="Image"
                type="file"
                errorMessage={validationErrors?.image}
                onChange={(e: React.FormEvent<HTMLInputElement>) => handleChange('image', e.currentTarget.files?.[0])}
            />
            <SelectField
                label="Related blogs"
                value={formValues.related_blogs}
                onChange={(value) => handleChange('related_blogs', value)}
                getOptionLabel={(blog: BlogPost) => blog.title}
                getOptionValue={(blog: BlogPost) => blog.id.toString()}
                options={allBlogs}
                isOptionDisabled={() =>
                    !!formValues.related_blogs && formValues.related_blogs?.length >= MAX_RELATED_BLOGS
                }
                isMulti
            />
            <SelectField
                label="Tags"
                value={formValues.tags}
                onChange={(value) => handleChange('tags', value)}
                getOptionLabel={(blog: Tag) => blog.name}
                getOptionValue={(blog: Tag) => blog.id.toString()}
                options={allTags}
                isMulti
            />
            {Actions}
        </StyledContent>
    );
    const SEOTab = (
        <StyledContent>
            <h1>{createMode ? 'Create' : 'Edit'} Blog</h1>
            <InputField
                value={formValues.meta_title}
                label="Meta title"
                errorMessage={validationErrors?.metaTitle}
                onChange={(e) => handleChange('meta_title', e.target.value)}
            />
            <InputField
                value={formValues.meta_description}
                label="Meta description"
                errorMessage={validationErrors?.metaDescription}
                onChange={(e) => handleChange('meta_description', e.target.value)}
            />
            <InputField
                value={formValues.meta_keywords}
                label="Meta keywords"
                errorMessage={validationErrors?.metaKeywords}
                onChange={(e) => handleChange('meta_keywords', e.target.value)}
            />

            <SwitchField
                value={formValues.no_index}
                label="No index"
                errorMessage={validationErrors?.noIndex}
                onChange={(e) => handleChange('no_index', e.target.checked)}
            />
            <SwitchField
                value={formValues.no_follow}
                label="No follow"
                errorMessage={validationErrors?.noFollow}
                onChange={(e) => handleChange('no_follow', e.target.checked)}
            />
            <SwitchField
                value={formValues.hide_in_sitemap}
                label="Hide in sitemap"
                errorMessage={validationErrors?.hideInSitemap}
                onChange={(e) => handleChange('hide_in_sitemap', e.target.checked)}
            />
            {Actions}
        </StyledContent>
    );
    return (
        <Tabs
            tabs={[
                { name: 'General', content: GeneralTab },
                { name: 'SEO', content: SEOTab },
            ]}
        ></Tabs>
    );
};

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 1140px;
    margin: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    .form-actions {
        display: flex;
        gap: 1rem;
        margin-bottom: 5rem;
    }
    section {
        display: flex;
        justify-content: center;
        margin-top: 3rem;
        background-color: ${(props) => props.theme.palette.black};
        img {
            border-radius: 1rem;
        }
    }
`;

export default BlogDetails;
