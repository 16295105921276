export const toDisplayDate = (date: string): string => {
    return `${new Date(date).getDate().toString().padStart(2, '0')}.${(new Date(date).getMonth() + 1)
        .toString()
        .padStart(2, '0')}.${new Date(date).getFullYear()}.`;
};

export const toDbDate = (date?: string | null): string => {
    if (!date) return '';
    const dateObject = new Date(date);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    return `${year}-${month}-${day}`;
};

export const toDisplayDateTime = (date?: string | null, short?: boolean): string => {
    if (!date) return '';
    const datePart = toDisplayDate(date);
    const timePart = toDisplayTime(date, short);
    return `${datePart} ${timePart}`;
};
export const toDisplayTime = (date: string, short?: boolean): string => {
    const dateObject = new Date(date);
    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    const seconds = dateObject.getSeconds().toString().padStart(2, '0');

    if (short) {
        return `${hours}:${minutes}`;
    }
    return `${hours}:${minutes}:${seconds}`;
};
