import { FunctionComponent } from 'react';
import styled from 'styled-components';
import LogoTopbar from '@/assets/icons/logoHeader.png';
import Hamburger from '@/assets/icons/hamburger.svg';
import { breakpoints } from '@/global/theme';
import useToggle from '@/hooks/useToggle';
import SideBar from '@/components/sideBar';
import { useStore } from '@/store';
import { useNavigate } from 'react-router-dom';

const TopBar: FunctionComponent = () => {
    const mobileMenu = useToggle();
    const nav = useNavigate();
    const logout = useStore((store) => store.logout);
    const handleLogout = (): void => {
        logout();
        nav('/');
    };

    return (
        <StyledTopBar>
            <div className="topBarContent">
                <div>
                    <img src={LogoTopbar} alt="logo icon" />
                </div>
                <div className="right">
                    <button onClick={handleLogout}>Logout</button>
                    <button
                        onClick={() => {
                            mobileMenu.toggle();
                        }}
                    >
                        <img src={Hamburger} alt="hamburger icon" className="hamburger" />
                    </button>
                </div>
            </div>
            {mobileMenu.isOpen && <SideBar isMobile handleClose={mobileMenu.close} />}
        </StyledTopBar>
    );
};

const StyledTopBar = styled.div`
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    background-color: #ffffff;
    box-shadow:
        rgba(0, 0, 0, 0.1) 0 2px 4px 0,
        rgba(0, 0, 0, 0.06) 0 0 2px 0;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    padding: 0.5rem 1rem;

    .topBarContent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .hamburger {
            display: none;
            z-index: 200;
            @media ${breakpoints.sm} {
                display: block;
            }
        }
        .right {
            display: flex;
            gap: 1rem;
            align-items: center;
        }
    }
`;

export default TopBar;
