import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '@/components/buttons';
import InputField from '@/components/inputs/inputField';
import { createVideo, editVideo, getVideo } from '@/communication/video';
import { ServerError } from '@/types/communication/serverResponse';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from '@/hooks/useFetch';
import { BackButton } from '@/components/buttons/backButton';

interface Props {
    createMode?: boolean;
}

interface FormValues {
    url: string;
    sort: number | null;
    title: string;
    description: string;
    image: string | File;
}

const VideoDetails: FunctionComponent<Props> = (props) => {
    const { createMode } = props;
    const { videoId: videoIdParam } = useParams();
    const videoId = Number(videoIdParam);
    const nav = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formValues, setFormValues] = useState<FormValues>({
        url: '',
        sort: null,
        title: '',
        description: '',
        image: '',
    });
    const [validationErrors, setValidationErrors] = useState<any>();
    const { data, status } = useFetch({
        queryFn: () => getVideo(videoId),
        disabled: Number.isNaN(videoId),
    });

    const handleChange = (fieldTitle: keyof FormValues, value): void => {
        setFormValues((prevValues) => ({ ...prevValues, [fieldTitle]: value }));
    };

    const handleSubmit = async (): Promise<void> => {
        setIsSubmitting(true);
        setValidationErrors(null);
        try {
            await (createMode ? createVideo(formValues) : editVideo({ id: videoId, ...formValues }));
            nav('/videos');
        } catch (error) {
            const serverError = error as ServerError;
            if (serverError?.type === 'validation') {
                setValidationErrors(serverError.payload);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (status === 'fetched' && data) {
            setFormValues(data);
        }
    }, [data, status]);

    if (status === 'loading') {
        return <>Loading</>;
    }
    return (
        <StyledContent>
            <h1>{createMode ? 'Create' : 'Edit'} Video</h1>
            <InputField
                value={formValues.url}
                label="Url"
                errorMessage={validationErrors?.url}
                onChange={(e) => handleChange('url', e.target.value)}
            />
            <InputField
                value={formValues.sort}
                type="number"
                label="Sort"
                errorMessage={validationErrors?.sort}
                onChange={(e) => handleChange('sort', e.target.value)}
            />
            <InputField
                value={formValues.title}
                label="Title"
                errorMessage={validationErrors?.title}
                onChange={(e) => handleChange('title', e.target.value)}
            />
            <InputField
                value={formValues.description}
                label="Description"
                errorMessage={validationErrors?.description}
                onChange={(e) => handleChange('description', e.target.value)}
            />
            <InputField
                label="Image"
                type="file"
                errorMessage={validationErrors?.image}
                onChange={(e: React.FormEvent<HTMLInputElement>) => handleChange('image', e.currentTarget.files?.[0])}
            />
            <div className="form-actions">
                <Button onClick={handleSubmit} disabled={isSubmitting}>
                    {createMode ? 'Create' : 'Save'}
                </Button>
                <BackButton />
            </div>
        </StyledContent>
    );
};

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 1140px;
    margin: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    .form-actions {
        display: flex;
        gap: 1rem;
        margin-bottom: 5rem;
    }
`;

export default VideoDetails;
